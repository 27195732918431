import { css } from "@emotion/react";

import { dimensions } from "@styles";

const calculatePercentWidth = (width: number) => {
  return `${(width / 12) * 100}%`;
};

export const column = css`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.5rem;
`;

export const widthOptions = {
  narrow: css`
    flex: none;
  `,
  full: css`
    flex: none;
    width: 100%;
  `,
};

export const paddingless = css`
  padding: 0;
`;

export const textAlignment = {
  left: css`
    text-align: left;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
  `,
};

export const columnWidths = (width: number) => css`
  flex: none;
  width: ${calculatePercentWidth(width)};
`;

export const sizes = (width: number) => css`
  @media only screen and (min-width: ${dimensions.tablet}) {
    ${columnWidths(width)}
  }
`;

export const offsetSize = (offset: number) => css`
  margin-left: ${calculatePercentWidth(offset)};
`;
