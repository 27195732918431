import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const columns = css`
  @media only screen and (min-width: ${dimensions.tablet}) {
    display: flex;
  }
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const preserveMobileColumns = css`
  display: flex;
`;

export const multiline = css`
  flex-wrap: wrap;
`;

export const marginless = css`
  margin: 0;
`;

export const centered = css`
  justify-content: center;
`;
